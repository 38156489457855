<template>
  <div class="sidepanel_trdocs">
    <div class="sidepanel__content">
      <div>
        <div class="logo">
          <img src="@/assets/img/sidepanel/logo.svg" alt="Лично трудово досие" />
        </div>
        <div class="user-switch" v-if="adminOrHR">
          <span @click="selectShow('all')" :class="{ active: show === 'all' }">АДМИН/ЧР</span>
          <span class="icon">}</span>
          <span @click="selectShow('my')" :class="{ active: show === 'my' }">СЛУЖИТЕЛ</span>
        </div>
        <div class="links" :class="{ 'links--less-margin': adminOrHR }">
          <p>ЕЛЕКТРОННИ ДОКУМЕНТИ</p>
          <a @click="selectType('all')" :class="{ active: type === 'all' }">
            <span class="icon">v</span>
            <span>ВСИЧКИ ЕЛЕКТРОННИ ДОКУМЕНТИ</span>
            <p class="tooltips" v-html="'Всички документи - от и за мен'" />
          </a>
          <a class="green" @click="selectType('approved')" :class="{ active: type === 'approved' }">
            <span class="icon">6</span>
            <span>АКТИВНИ</span>
            <p class="tooltips" v-html="'Документи, събрали нужния брой подписи'" />
          </a>
          <a class="orange" @click="selectType('waiting')" :class="{ active: type === 'waiting' }">
            <span class="icon">6</span>
            <span>ОЧАКВАЩИ ПОДПИС</span>
            <p class="tooltips" v-html="'Документи, които очакват един или повече подписи'" />
          </a>
          <a class="red" @click="selectType('notApproved')" :class="{ active: type === 'notApproved' }">
            <span class="icon">6</span>
            <span>НЕОДОБРЕНИ</span>
            <p class="tooltips" v-html="'Документи, които не са одобрени от ЧР'" />
          </a>
          <a class="red" @click="selectType('annulled')" :class="{ active: type === 'annulled' }">
            <span class="icon">|</span>
            <span>АНУЛИРАНИ</span>
            <p class="tooltips" v-html="'Документи, които са анулирани'" />
          </a>
          <p>РЪЧНОКАЧЕНИ ДОКУМЕНТИ</p>
          <a @click="selectType('allManual', true)" :class="{ active: type === 'allManual' }">
            <span class="icon">v</span>
            <span>ВСИЧКИ РЪЧНО КАЧЕНИ ДОКУМЕНТИ</span>
            <p class="tooltips" v-html="'Всички документи - от и за мен'" />
          </a>
          <a class="green" @click="selectType('finishedManual', true)" :class="{ active: type === 'finishedManual' }">
            <span class="icon">6</span>
            <span>ПОТВЪРДЕНИ ДОКУМЕНТИ</span>
            <p class="tooltips" v-html="'Документи, събрали нужния брой подписи'" />
          </a>
          <a class="orange" @click="selectType('waitingManual', true)" :class="{ active: type === 'waitingManual' }">
            <span class="icon">6</span>
            <span>ОЧАКВАЩИ ПОТВЪРЖДЕНИЕ</span>
            <p class="tooltips" v-html="'Документи, които очакват един или повече подписи'" />
          </a>
          <template v-if="adminOrHR && show === 'all'">
            <p>СПРАВКИ</p>
            <a @click="selectType('inquiryAdditional')" :class="{ active: type === 'inquiryAdditional' }">
              <span class="icon">~</span>
              <span>СПРАВКИ И ЕКСПОРТИ</span>
            </a>
          </template>
        </div>
      </div>
      <DragAndDropUpload class="drag-upload-component" />
    </div>

    <!-- <div class="portal-animation" key="default-state">
        <img src="@/assets/img/sidepanel/portal-animation.svg" />
      </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Tooltip from './Tooltip';
// import config from '../../../config-public.json';
export default {
  components: {
    // Tooltip,
    DragAndDropUpload: () => import('@/components/DragAndDropUpload'),
  },
  computed: {
    ...mapGetters(['isSidePanelOpen', 'isAuthenticated', 'currentUser', 'twoFA']),
    adminOrHR() {
      return this.isAuthenticated ? this.currentUser.roles.admin || this.currentUser.roles.hr : false;
    },
  },
  watch: {
    isAuthenticated(to) {
      if (!to && window.innerWidth <= 768 && !this.isSidePanelOpen) {
        // if (to && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
    $route(to) {
      if (to.query.type) if (this.type !== to.query.type) this.type = to.query.type;
    },
    '$route.name'() {
      if (this.isSidePanelOpen && window.innerWidth <= 768 && this.isAuthenticated) {
        // if (this.isSidePanelOpen && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
  },
  data() {
    return {
      // config,
      show: 'my',
      type: '',
      query: {},
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        location.reload();
      });
    },
    selectShow(s) {
      if (this.show !== s) {

        this.show = s;
        this.pushToRoute(this.$route.name !== 'Home');
      }
    },
    selectType(t, isManual) {
      if (this.type !== t) {
        this.type = t;
        this.pushToRoute(isManual);
      }
    },
    pushToRoute(isManual) {
      if (this.type) {
        this.query.type = this.type;
      }
      if (this.show) {
        this.query.show = this.show;
      }
      if (this.$route.query.search) {
        this.query.search = this.$route.query.search;
      } else {
        delete this.query.search;
      }
      if (this.$route.query.filter) {
        this.query.filter = this.$route.query.filter;
      } else {
        delete this.query.filter;
      }
      if (this.$route.query.dateFrom && this.$route.query.dateTo) {
        this.query.dateFrom = this.$route.query.dateFrom;
        this.query.dateTo = this.$route.query.dateTo;
      }
      this.query.t = new Date().getTime();

      this.$router
        .push({
          path: isManual ? '/manually-uploaded' : '/',
          query: this.query,
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.$router.onReady(() => {
      if (this.$route.name === 'Home') {
        if (this.$route.query.openDocument) this.query.openDocument = this.$route.query.openDocument;
        delete this.query.openDocument;
      }

      if (this.$route.query.type) {
        this.type = this.$route.query.type;
        this.show = this.$route.query.show;
      } else {
        this.show = this.currentUser.roles.admin || this.currentUser.roles.hr ? 'all' : 'my';
        if (this.$route.name === 'Home') {
          this.selectType('all');
        } else {
          this.selectType('allManual');
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.sidepanel_trdocs {
  height: 100%;
}
</style>
