<template>
  <div class="document-form-type">
    <div class="row center text-center column">
      <h1>
        ЗАЯВЛЕНИЕ ЗА ПРОМЯНА <br />
        НА ОСНОВНА ЗАПЛАТА
      </h1>
      <p class="doc-number" v-if="d.number">№{{ d.numberPrefix }}-{{ d.number }}</p>
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>От</strong>
      </p>
    </div>
    <section class="rows-wrapper">
      <div class="row">
        <Input
          style="width: 75%"
          icon="F"
          placeholder="Име Презиме Фамилия"
          label="От Работник/Служител (изберете или потърсете име)"
          type="dropdown"
          @fetch="$emit('fetch-employees', $event)"
          :locked="d._id || isCurrUserDocUser"
          :list="employees"
          prop="name"
          identifier="_id"
          v-model="d.form.user"
        />
        <Input
          style="width: 25%"
          icon="+"
          placeholder="№"
          label="Служебен номер"
          v-model="d.form.user.number"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="b"
          placeholder="Длъжност"
          label="Длъжност на работника или служителя (автом.)"
          :value="d.form.user.position ? d.form.user.position.name : ''"
          :autoFill="true"
        />
        <Input
          :icon="'&'"
          placeholder="Структурно звено"
          label="Структурно звено (участък, цех, лаборатория, отдел, отделение, група)"
          :value="d.form.user.structuralUnit ? d.form.user.structuralUnit.name : ''"
          :autoFill="true"
        />
      </div>
      <div class="row">
        <Input
          icon="-"
          placeholder="График"
          label="График"
          :value="d.form.user.company === 'КЦМ 2000' ? 'Редовен' : d.form.user.schedule"
          :autoFill="true"
        />
        <Input :icon="')'" placeholder="Телефон" label="Телефон" v-model="d.form.user.phone" :autoFill="true" />
      </div>
    </section>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>Моля, въведете ръчно вашето ЕГН</strong>
      </p>
    </div>

    <div class="row">
      <Input
        icon="+"
        placeholder="ЕГН"
        label="ЕГН"
        v-model="d.form.egn"
        :maxlength="10"
        :digitsOnly="true"
        :locked="d._id"
      />
    </div>

    <div class="row center text-center">
      <p class="smaller-line-height">
        <strong>Г-н Изпълнителен директор,</strong>
      </p>
    </div>

    <div class="row center text-center">
      <p>Моля трудовият ми договор да бъде ИЗМЕНЕН както следва:</p>
    </div>

    <div class="row row--align-center">
      <!-- <section class="row column" style="width: 40%">
        <Input
          label="Ниво"
          type="checkbox"
          :value="d.form.salaryType === 'Ниво'"
          :locked="d._id"
          @input="$set(d.form, 'salaryType', $event ? 'Ниво' : '')"
        />
        <Input
          label="Основна заплата"
          type="checkbox"
          :value="d.form.salaryType === 'Основна заплата'"
          :locked="d._id"
          @input="$set(d.form, 'salaryType', $event ? 'Основна заплата' : ''), deleteSalaryTypeInputs()"
        />
      </section> -->
      <Input
        icon="b"
        placeholder="Нова основна заплата"
        label="Нова основна заплата"
        v-model="d.form.newPosition"
        :disabled="d.form.salaryType === 'Основна заплата'"
        :locked="d.form.salaryType === 'Основна заплата' ? false : d._id"
      />
    </div>

    <!-- <div class="row center text-center">
      <p>Защитил съм</p>
    </div>

    <div class="row">
      <Input
        icon="/"
        placeholder="разряд *"
        label="разряд"
        v-model="d.form.discharge"
        :disabled="d.form.salaryType === 'Основна заплата'"
        :locked="d.form.salaryType === 'Основна заплата' ? false : d._id"
      />
      <Input
        icon="."
        placeholder="протокол №*"
        label="с протокол №"
        v-model="d.form.protocol"
        :disabled="d.form.salaryType === 'Основна заплата'"
        :locked="d.form.salaryType === 'Основна заплата' ? false : d._id"
      />
      <Input
        icon="C"
        type="datepicker"
        placeholder="Дата *"
        label="от дата"
        v-model="d.form.dateOfProtection"
        :yearFirst="true"
        :disabled="d.form.salaryType === 'Основна заплата'"
        :locked="d.form.salaryType === 'Основна заплата' ? false : d._id"
      />
    </div> -->

    <div class="row row--background">
      <Input
        icon="C"
        placeholder="Дата на създаване"
        label="Дата на създаване"
        v-model="d.form.createdAt"
        :locked="d._id"
        type="datepicker"
      />
      <Input
        icon="$"
        :placeholder="d.form.user.name ? d.form.user.name : 'Име, Фамилия'"
        label="Служител - име и подпис"
        type="signature"
        :locked="d._id"
        :value="d.form.signatures.user"
        @signed="d.form.signatures.user = $event"
      />
    </div>

    <template v-if="d._id">
      <div class="brake">Попълва се от отдел "УЧР"</div>

      <div class="row center">
        <Approval
          pre="ОТДЕЛ УЧР"
          yes="ОДОБРЯВА"
          no="НЕ ОДОБРЯВА"
          post="ЗАЯВЛЕНИЕТО"
          v-model="d.form.approved"
          :disabled="stageTwoDone || !isCurrUserDocHr"
          :locked="stageTwoDone || !isCurrUserDocHr"
        />
      </div>
      <section class="rows-wrapper">
        <div class="row">
          <Input
            v-if="d.form.approved === false"
            :icon="'&'"
            type="textarea"
            :maxlength="165"
            placeholder="Причина за неодобрение"
            label="Въведете причина за неодобрение до 165 знака"
            v-model="d.form.disapprovalReason"
            :locked="stageTwoDone"
          />
        </div>
        <div class="row">
          <!-- <Input
            style="width: 25%"
            icon="+"
            placeholder="Вх. №"
            label="Входящ номер"
            v-model="d.form.incomingNumber"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
          /> -->
          <Input
            style="width: 25%"
            icon="C"
            placeholder="От дата"
            label="От дата"
            type="datepicker"
            v-model="d.form.probationFrom"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
          <Input
            style="width: 75%"
            icon="O"
            placeholder="Изберете ръководител"
            label="Одобряващ ръководител 1 (изберете или потърсете име)"
            type="dropdown"
            @fetch="$emit('fetch-supervisors', $event)"
            :list="supervisors"
            prop="name"
            :identifier="d.form.receiver ? '_id' : false"
            v-model="d.form.receiver"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
        </div>
        <div class="row">
          <Input
            :icon="'&'"
            type="textarea"
            :maxlength="165"
            placeholder="Други условия"
            label="Въведете свободен текст до 165 знака"
            v-model="d.form.otherTerms"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
            :nonRequired="true"
          />
        </div>
        <!-- <div class="row"> 
        <Input
            icon="C"
            placeholder="Изпитателен срок за длъжността"
            label="Изпитателен срок за длъжността"
            v-model="d.form.probationTime"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
          />
        </div> -->
        <div class="row">
          <Input
            :icon="'&'"
            placeholder="Структурно звено"
            label="Структурно звено"
            v-model="d.form.structuralUnit"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
        </div>
        <div class="row">
          <Input
            icon="b"
            placeholder="Длъжност"
            label="Длъжност"
            v-model="d.form.position"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
        </div>
        <div class="row">
          <Input
            icon="+"
            placeholder="Код по НКПД"
            label="НКПД"
            type="dropdown"
            prop="name"
            propTwo="label"
            reversedProp="true"
            :list="nkpd"
            v-model="d.form.nkdp"
            :maxlength="8"
            :minlength="8"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
            :selectSearch="true"
          />
          <Input
            icon="/"
            placeholder="Ниво"
            label="длъжностно ниво"
            v-model="d.form.jobLevel"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
          <Input
            icon=","
            placeholder="Сума"
            label="Разчетна основна заплата"
            v-model="d.form.mainSalary"
            :digitsOnly="true"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
          <!-- <Input
            icon="C"
            placeholder="Дата"
            label="срок на договора до"
            type="datepicker"
            :yearFirst="true"
            v-model="d.form.contractEndDate"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
          /> -->
        </div>
        <div class="row">
          <Input
            icon="q"
            placeholder="часа"
            label="Продължителност на раб. ден (в часове)"
            v-model="d.form.workDayDuration"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
          <Input
            icon="q"
            placeholder="часа"
            label="Продължителност Смяна (в часове)"
            v-model="d.form.shiftDuration"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
            :nonRequired="true"
          />
          <Input
            icon="q"
            placeholder="часа"
            label="Продължителност Седмица (в часове)"
            v-model="d.form.weekDuration"
            :disabled="!stageTwoDone ? disabledHrFields : !d.form.approved"
            :locked="stageTwoDone"
          />
        </div>
      </section>
      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtHr"
          type="datepicker"
          :disabled="!stageTwoDone ? !isCurrUserDocHr : !d.form.approved"
          :locked="stageTwoDone"
        />
        <Input
          icon="$"
          placeholder="Минка Димитрова Бакалова-Каменова"
          label="УЧР - име и подпис"
          type="signature"
          :value="d.form.signatures.hr"
          @signed="d.form.signatures.hr = $event"
          :disabled="!stageTwoDone ? !isCurrUserDocHr : !d.form.approved"
          :locked="stageTwoDone"
        />
      </div>
    </template>

    <template v-if="d.form.approved && stageTwoDone">
      <div class="brake">Попълва се от ръководител 1 - {{ d.form.receiver.name }}</div>

      <div class="row center text-center">
        <p>
          Съгласен съм, считано от {{ d.form.probationFrom | formatDate }}, основната работна заплата на
          {{ d.form.user.name }} да бъде променена:
        </p>
      </div>

      <div class="row row--align-center">
        <section class="row column" style="width: 40%">
          <Input
            label="разчетна заплата"
            type="checkbox"
            :value="d.form.salaryChange === 'разчетна заплата'"
            @input="$set(d.form, 'salaryChange', $event ? 'разчетна заплата' : '')"
            :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
            :locked="stageThreeDone"
          />
          <Input
            label="плюс"
            type="checkbox"
            :value="d.form.salaryChange === 'плюс'"
            @input="$set(d.form, 'salaryChange', $event ? 'плюс' : '')"
            :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
            :locked="stageThreeDone"
          />
          <Input
            label="минус"
            type="checkbox"
            :value="d.form.salaryChange === 'минус'"
            @input="$set(d.form, 'salaryChange', $event ? 'минус' : '')"
            :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
            :locked="stageThreeDone"
          />
        </section>

        <Input
          icon="C"
          placeholder="Сума"
          label="сума в лева"
          :digitsOnly="true"
          v-model="d.form.newSalary"
          :disabled="
            stageThreeDone && d.form.salaryChange === 'разчетна заплата'
              ? true
              : !stageThreeDone
              ? !isCurrUserDocReceiver || d.form.salaryChange === 'разчетна заплата'
              : false
          "
          :locked="stageThreeDone && !d.form.salaryChange === 'разчетна заплата'"
        />
      </div>

      <div class="row center text-center">
        <p class="smaller-line-height">
          <strong>Лицето ще работи в смяна</strong>
        </p>
      </div>

      <div class="row">
        <section class="row column">
          <Input
            icon="-"
            placeholder="Смяна"
            label="Смяна"
            v-model="d.form.newShift"
            :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
            :locked="stageThreeDone"
          />
          <Input
            icon="O"
            placeholder="Изберете ръководител"
            label="Одобряващ ръководител 2 (изберете или потърсете име)"
            type="dropdown"
            @fetch="$emit('fetch-filtered-supervisors', $event)"
            :list="supervisorsTwo"
            prop="name"
            :identifier="d.form.receiverTwo ? '_id' : false"
            v-model="d.form.receiverTwo"
            :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
            :locked="stageThreeDone"
          />
        </section>
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiver"
          type="datepicker"
          :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
          :locked="stageThreeDone"
        />
        <Input
          icon="$"
          :placeholder="d.form.receiver ? d.form.receiver.name : ''"
          label="Ръководител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiver"
          @signed="d.form.signatures.receiver = $event"
          :disabled="!stageThreeDone ? !isCurrUserDocReceiver : false"
          :locked="stageThreeDone"
        />
      </div>
    </template>

    <template v-if="stageThreeDone">
      <div class="brake">Попълва се от ръководител 2 - {{ d.form.receiverTwo.name }}</div>

      <div class="row center text-center">
        <p>Аз, {{ d.form.receiverTwo.name }}, ръководител на {{ d.form.user.name }},</p>
      </div>

      <div class="row center">
        <Approval
          yes="ОДОБРЯВАМ"
          no="НЕ ОДОБРЯВАМ"
          post="ТЕКУЩОТО ЗАЯВЛЕНИЕ"
          v-model="d.form.approvedByRecieverTwo"
          :disabled="!isCurrUserDocReceiverTwo"
        />
      </div>

      <div class="row row--background">
        <Input
          icon="C"
          placeholder="Дата на създаване"
          label="Дата на създаване"
          v-model="d.form.createdAtReceiverTwo"
          type="datepicker"
          :disabled="!isCurrUserDocReceiverTwo"
        />
        <Input
          icon="$"
          :placeholder="d.form.receiverTwo ? d.form.receiverTwo.name : ''"
          label="Ръководител - име и подпис"
          type="signature"
          :value="d.form.signatures.receiverTwo"
          @signed="d.form.signatures.receiverTwo = $event"
          :disabled="!isCurrUserDocReceiverTwo"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '../CreateDocumentInput';
import Approval from '../CreateDocumentApproval';
export default {
  components: {
    Input,
    Approval,
  },
  computed: {
    ...mapGetters(['currentUser']),
    disabledHrFields() {
      return this.stageTwoDone ? !this.currentUser.roles.admin : !this.isCurrUserDocHr || !this.d.form.approved;
    },
  },
  props: [
    'd',
    'employees',
    'supervisors',
    'supervisorsTwo',
    'nkpd',
    'invalid',
    'isCurrUserDocUser',
    'isCurrUserDocHr',
    'isCurrUserDocReceiver',
    'isCurrUserDocReceiverTwo',
  ],
  data() {
    return {
      stageTwoDone: false,
      stageThreeDone: false,
      notApproved: false,
    };
  },
  methods: {
    deleteSalaryTypeInputs() {
      this.d.form.newPosition = '';
      this.d.form.discharge = '';
      this.d.form.protocol = '';
      this.d.form.dateOfProtection = '';
    },
  },
  beforeMount() {
    if (this.d.form.signatures.hr && typeof this.d.form.approved === 'boolean') this.stageTwoDone = true;
    if (this.d.form.signatures.receiver) this.stageThreeDone = true;
    if (this.d.form.signatures.hr && !this.d.form.approved) this.notApproved = true;

    if (!this.d._id) {
      this.$set(this.d.form, 'receiver', {});
      this.$set(this.d.form.signatures, 'hr', null);
      this.$set(this.d.form.signatures, 'receiver', null);
      this.$set(this.d.form.signatures, 'receiverTwo', null);
      this.$set(this.d.form, 'salaryType', 'Ниво');
      this.$set(this.d.form, 'salaryChange', 'разчетна заплата');
      this.d.form.probationTime = '6 месеца';
    }
    if (!this.d.form.signatures.hr) this.d.form.createdAtHr = new Date();
    if (!this.d.form.signatures.receiver) this.d.form.createdAtReceiver = new Date();
    if (!this.d.form.signatures.receiverTwo) this.d.form.createdAtReceiverTwo = new Date();
  },
};
</script>
